<template>
  <div>

    <b-card-body class="h-100 p-0">
      <b-tabs card pills nav-wrapper-class="nav-wrapper rounded-0 border-0 pt-0" content-class="overflow-y h-100" fill
        v-model="tabIndex">
        <b-tab title="Training Configuration" Active>

          <b-card no-body class="h-100 bg-dark-navy custom border-1">
            <b-card-header class="px-3 title_staging"><strong>Model Configuration</strong></b-card-header>

            <div class="d-flex justify-content-space-between  mt-2">
              <div class="form-group w-100 mr-2">
                <label for="type">Model Type:</label>
                <b-form-select id="type" v-model="config.model" required>
                  <option disabled value="">Select Model Type</option>
                  <option value="object-detection">Object-detection</option>
                  <option disabled value="classification">Classification</option>
                </b-form-select>
              </div>
              <div class="form-group w-100">
                <label for="source">Framework:</label>
                <b-form-select id="source" v-model="config.framework" required>
                  <option disabled value="">Select Framework</option>
                  <option value="Yolo3">RADIFY&reg; v2.7.4</option>
                  <option value="Scaled Yolo4">RADIFY&reg; v3.4.1</option>
                  <option value="Yolo8">RADIFY&reg; v3.7.1</option>
                  <option value="FastRCNN">RADIFY&reg; v4.0.0 Beta</option>
                </b-form-select>
              </div>
            </div>
          </b-card>
          <b-card no-body class="h-100 bg-dark-navy custom border-1"
            v-if="config.model !== '' && config.framework !== ''">
            <b-card-header class="px-3 title_staging"><strong>Control Mechanisms</strong></b-card-header>

            <div class="mt-2">
              <b-row>
                <b-col>
                  <b-form-checkbox v-model="config.controls.epoch.selected">
                    Epochs
                  </b-form-checkbox>
                </b-col>
                <b-col v-if="config.controls.epoch.selected">
                  <b-form-input placeholder="Target" v-model="config.controls.epoch.target"> Target</b-form-input>
                </b-col>
                <b-col v-if="config.controls.epoch.selected">
                  <b-form-input placeholder="Maximum" v-model="config.controls.epoch.max"> Maximum</b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-checkbox v-model="config.controls.duration.selected">
                    Duration
                  </b-form-checkbox>
                </b-col>
                <b-col v-if="config.controls.duration.selected">
                  <b-form-input placeholder="Target" v-model="config.controls.duration.target"> Target</b-form-input>
                </b-col>
                <b-col v-if="config.controls.duration.selected">
                  <b-form-input placeholder="Maximum" v-model="config.controls.duration.max"> Maximum</b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-checkbox v-model="config.controls.map.selected">
                    Mean Average Precision
                  </b-form-checkbox>
                </b-col>
                <b-col v-if="config.controls.map.selected">
                  <b-form-input placeholder="Target" v-model="config.controls.map.target"> Target</b-form-input>
                </b-col>
                <b-col v-if="config.controls.map.selected">
                  <b-form-input placeholder="Maximum" v-model="config.controls.map.max"> Maximum</b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p>
                    Test ({{ config.controls.split.value }} %) /Train ({{ 100 - config.controls.split.value }} %) Split
                    Ratio
                  </p>
                </b-col>
                <b-col cols=8 class="ml-3">
                  <b-form-input placeholder="Target" type="range" min="5" max="30"
                    v-model="config.controls.split.value"> Target</b-form-input>
                </b-col>
              </b-row>
            </div>
          </b-card>
          <b-card no-body class="h-100 bg-dark-navy custom border-1"
            v-if="config.model !== '' && config.framework !== '' && control_selected">
            <b-card-header class="px-3 title_staging"><strong>Class Configuration</strong></b-card-header>

            <div class="mt-2">
              <b-row>
                <b-col>
                  <b-row class="mb-0 pb-0">
                    <b-col cols="4" class="ml-4"><strong>Pathologies:</strong></b-col>
                    <b-col cols="4">{{ totalP }} Total Pathologies</b-col>
                  </b-row>
                  <b-col>
                    <!-- <b-row v-for="path in Object.keys(presentPaths)" :key="'__' +path" heigth="5vh" class="mb-1 selectrow">
                    <b-col cols="4">
                      <b-form-checkbox v-model="presentPaths[path].selected"  @change="paths_selected($event)"> {{path}}</b-form-checkbox>
                    </b-col>
                    <b-col l cols="8">
                      <b-row class="mb-0">
                        <b-col cols="6">
                          {{presentPaths[path].count}}
                        </b-col>
                      </b-row>
                      <b-progress :value="presentPaths[path].count" :max="totalP" variant="warning" animated height="5px"></b-progress>
                    </b-col>
                  </b-row> -->

                    <b-table striped hover :items="pathTable" :fields="fields">
                      <template #cell(name)="data">
                        <!-- <small>{{ data.item.name }}</small> -->
                        <b-form-checkbox v-model="presentPaths[data.item.name].selected"
                          @change="paths_selected($event)"> {{ data.item.name }}</b-form-checkbox>
                      </template>
                      <template #cell(count)="data">
                        <small>{{ data.item.count }}</small>
                        <b-progress :value="data.item.count" :max="totalP" variant="warning" animated
                          height="5px"></b-progress>
                      </template>
                    </b-table>
                  </b-col>
                </b-col>

                <b-col :key="render">
                  <b-row class="mb-0 pb-0">
                    <b-col cols="4" class="ml-2"><strong>Diagnoses:</strong></b-col>
                    <b-col cols="4">{{ totalD }} Total Diagnoses</b-col>
                  </b-row>
                  <b-table striped hover :items="diaTable" :fields="fields">
                    <template #cell(name)="data">
                      <!-- <small>{{ data.item.name }}</small> -->
                      <b-form-checkbox v-model="presentDias[data.item.name].selected" disabled> {{ data.item.name
                        }}</b-form-checkbox>
                    </template>
                    <template #cell(count)="data">
                      <small>{{ data.item.count }}</small>
                      <b-progress :value="data.item.count" :max="totalP" variant="warning" animated
                        height="5px"></b-progress>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </div>
          </b-card>
          <b-card no-body class="h-100 bg-dark-navy custom border-1 pb-1"
            v-if="config.model !== '' && config.framework !== '' && control_selected && displayGallery">
            <b-card-header class="px-3 title_staging"><strong>Image Configuration</strong></b-card-header>

            <div class="mt-2">
              <b-row class="pt-1 ml-5">
                <b-input-group class="rounded-pill navy-fields" :key='render'>
                  <template #prepend>
                    <b-input-group-text class="rounded-tr-0 rounded-br-0 rounded-pill navy-fields form-control">
                      <font-awesome-icon icon="search" />
                    </b-input-group-text>
                  </template>
                  <template #append>
                    <b-input-group-text class="rounded-tl-0 rounded-bl-0 rounded-pill navy-fields form-control">
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="search" placeholder="Search Image Name" style="width: 25rem"></b-form-input>
                  <b-col>
                    <b-dropdown class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill"
                      :text="filterChoice === '' ? 'All' : filterChoice" variant="warn">
                      <b-dropdown-item @click="filterChoice = 'All'">All</b-dropdown-item>
                      <b-dropdown-item v-for="path in selectedPaths" :key="path" @click="filterChoice = path">{{
          path }}</b-dropdown-item>
                    </b-dropdown>
                    <p class="mt-0 pt-0 pl-3">Pathologies</p>
                  </b-col>
                  <!-- <b-col>
              <b-dropdown class="my-class ml-3 btn px-3 btn-sm btn-yellow rounded-pill"
                text="Pathology" variant="warn">
                  <b-checkbox class="mx-2" v-for="_p in pathList" :key="_p.label" v-model="filterChoicePathArray[_p.label]" @change="addPath(_p.label)">{{
                                _p.label }}</b-checkbox>
              </b-dropdown>
            </b-col> -->
                </b-input-group>
              </b-row>
              <b-row class="pt-1 ml-5">
                <div v-if="selectedImageCount > 0" class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill">
                  {{ selectedImageCount }} image{{ selectedImageCount > 1 ? 's' : '' }} selected
                </div>
                <p v-else>No images selected, <a href="#" @click.prevent="selectAll()">select all?</a></p>
              </b-row>
              <b-row class="mb-4">
                <b-col md="3" class="mb-2 gx-1" v-for="image in paginatedData" :key="'_' + image.id" cols="3">
                  <div class="image-container text-center ">
                    <b-col>
                      <!-- :class="{ selected: image === selectedImage }" -->
                      <input type="checkbox" v-model="selectedImages[image.id]" class="image-checkbox"
                        :key="selectedImages[image.id]" />
                      <img class="img-fluid " :src="image.url + '?thumb=yes'"
                        :class="image.details.image_set && image.details.image_set.name ? 'image-set' : ''"
                        :style="'border-color: ' + image.styleFlag + ';'" @click.prevent="openBottomSheet(image)" />
                      <div>
                        <small class="image-name text-wite">{{ image.details.image_set && image.details.image_set.name ?
          image.details.image_set.name : image.details.imgName }}</small>
                      </div>
                      <small class="indicator" :class="selectedImages[image.id] ? 'trained-green' : 'trained-red'">
                        {{ selectedImages[image.id] ? 'Selected' : 'Not Selected' }}
                      </small>

                      <small class="indicator-set trained-green"
                        v-if="image.details.image_set && image.details.image_set.name">
                        {{ image.details.image_set.sequence }}count
                      </small>
                    </b-col>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-row align-h="center">
              <b-button class="ml-3 btn  btn-sm btn-yellow rounded-pill" @click="prevPage"
                :disabled="this.pageNumber === 0">
                <i class="fa fa-chevron-left"></i>
              </b-button>
              <b-button v-if="pageNumber - 3 > 0" class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(0)"
                :disabled="this.pageNumber === 0">
                1
              </b-button>
              <p class="ml-3  mt-2" v-if="pageNumber - 3 > 0">...</p>
              <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber - 2)"
                v-if="pageNumber - 2 >= 0">
                {{ pageNumber - 1 }}
              </b-button>
              <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber - 1)"
                v-if="pageNumber - 1 >= 0">
                {{ pageNumber }}
              </b-button>
              <b-button class="ml-3 btn btn-sm btn-blue rounded-pill" style="text-decoration: underline;">
                {{ pageNumber + 1 }}
              </b-button>
              <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber + 1)"
                v-if="pageNumber + 1 < maxPages">
                {{ pageNumber + 2 }}
              </b-button>
              <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="setPage(pageNumber + 2)"
                v-if="pageNumber + 2 < maxPages">
                {{ pageNumber + 3 }}
              </b-button>
              <p class="ml-3 mt-2" v-if="pageNumber + 3 < maxPages">...</p>
              <b-button v-if="pageNumber + 3 < maxPages" class="ml-3 btn btn-sm btn-yellow rounded-pill"
                @click="setPage(maxPages - 1)">
                {{ maxPages }}
              </b-button>
              <b-button class="ml-3 btn btn-sm btn-yellow rounded-pill" @click="nextPage"
                :disabled="this.pageNumber + 1 === maxPages">
                <i class="fa fa-chevron-right"></i>
              </b-button>
              <b-dropdown :text="'' + itemsPerPage" class="ml-3 btn btn-sm btn-blue rounded-pill" variant="'primary'">
                <b-dropdown-item @click="setItemsPerPage(4)">4</b-dropdown-item>
                <b-dropdown-item @click="setItemsPerPage(8)">8</b-dropdown-item>
                <b-dropdown-item @click="setItemsPerPage(16)">16</b-dropdown-item>
                <b-dropdown-item @click="setItemsPerPage(32)">32</b-dropdown-item>
                <b-dropdown-item @click="setItemsPerPage(64)">64</b-dropdown-item>
                <b-dropdown-item @click="setItemsPerPage(128)">128</b-dropdown-item>
              </b-dropdown>
            </b-row>
          </b-card>
          <b-row
            v-if="config.model !== '' && config.framework !== '' && control_selected && displayGallery && selectedImageCount > 0"
            align-h="around">
            <b-button @click="save_config()" class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill">
              Save Configuration
            </b-button>
            <b-button class="ml-3 btn px-3 btn-blue rounded-pill" @click="trainingStart()">
              Preprecess data
            </b-button>
          </b-row>
        </b-tab>
        <b-tab title="Training Results">
          <b-card no-body class="h-100 bg-dark-navy custom border-1"
            v-if="config.model !== '' && config.framework !== '' && control_selected">
            <b-card-header class="px-3 title_staging"><strong>Class Configuration</strong></b-card-header>

            <div class="mt-2">
              <b-row>
                <b-col>
                  <b-row class="mb-0 pb-0">
                    <b-col cols="4" class="ml-4"><strong>Train:</strong></b-col>
                  </b-row>
                  <b-col>

                    <b-table striped hover :items="trainTable" :fields="fields">
                      <template #cell(name)="data">
                        <!-- <small>{{ data.item.name }}</small> -->
                        <p> {{ data.item.name }}</p>
                      </template>
                      <template #cell(count)="data">
                        <small>{{ data.item.count }}</small>
                        <b-progress :value="data.item.count" :max="totalP" variant="warning" animated
                          height="5px"></b-progress>
                      </template>
                    </b-table>
                  </b-col>
                </b-col>

                <b-col :key="render">
                  <b-row class="mb-0 pb-0">
                    <b-col cols="4" class="ml-2"><strong>Test:</strong></b-col>
                  </b-row>
                  <b-table striped hover :items="testTable" :fields="fields">
                    <template #cell(name)="data">
                      <!-- <small>{{ data.item.name }}</small> -->
                      <p>{{ data.item.name }}</p>
                    </template>
                    <template #cell(count)="data">
                      <small>{{ data.item.count }}</small>
                      <b-progress :value="data.item.count" :max="totalP" variant="warning" animated
                        height="5px"></b-progress>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </div>
            <a v-if=config.ttsplit class='ml-3' :href="config.ttsplit.details.url">Download Preprocessed data:</a>
          </b-card>
          <b-card no-body class="h-100 bg-dark-navy custom border-1"
            v-if="config.model !== '' && config.framework !== '' && control_selected">
            <b-card-header class="px-3 title_staging"><strong>Training Controls:</strong>  {{ this.status }}</b-card-header>
            <b-row class="mt-3" align-h="around">
              <b-button class="ml-3 btn px-3 btn-blue rounded-pill" @click="beginTraining()">
              Start
            </b-button>
            <b-button class="ml-3 btn px-3 btn-blue rounded-pill"  @click="_stopTraining()">
              Pause
            </b-button>
            <b-button class="ml-3 btn px-3 btn-blue rounded-pill" @click="_stopTraining()">
              Stop
            </b-button>
            <b-button class="ml-3 btn px-3 btn-blue rounded-pill" @click="trainingPoll()">
              Poll
            </b-button>
            </b-row>
          </b-card>
          <b-card no-body class="h-100 bg-dark-navy custom border-1"
            v-if="config.model !== '' && config.framework !== '' && control_selected">
            <b-card-header class="px-3 title_staging"><strong>AI Performance</strong></b-card-header>
            <b-row>
              <b-col cols="4" class="mt-2">
                <apexchart height="320" :options="cmOptions" :series="cmSeries"></apexchart>
              </b-col>
              <b-col cols="4" class="mt-2">
                <apexchart :options="aucOptions" :series="aucSeries"></apexchart>
              </b-col>
              <b-col cols="4" class="mt-2">
                <apexchart :options="prOptions" :series="prSeries"></apexchart>
              </b-col>
            </b-row>
          </b-card>
          <b-card no-body class="h-100 bg-dark-navy custom border-1"
            v-if="config.model !== '' && config.framework !== '' && control_selected">
            <b-card-header class="px-3 title_staging"><strong>Live Results</strong></b-card-header>
            <div id="chart">
              <apexchart ref="mapChart" type="line" height="350" :options="chartOptions" :series="series"></apexchart>
            </div>
            <div id="chart">
              <apexchart ref="mapChart" type="line" height="350" :options="chartOptionsCon" :series="seriesCon"></apexchart>
            </div>
            <div id="chart">
              <apexchart ref="mapChart" type="line" height="350" :options="chartOptionsPr" :series="seriesPR"></apexchart>
            </div>
            <div id="chart">
              <apexchart ref="mapChart" type="line" height="350" :options="chartOptionsF1" :series="seriesF1"></apexchart>
            </div>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-card-body>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { apiRoute } from './../../../../helpers/api-route'
export default {
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      intervalId: null,
      status: '',
      tabIndex: 0,
      config: {
        model: '',
        framework: '',
        controls: {
          epoch: { selected: false, target: null, max: null },
          duration: { selected: false, target: null, max: null },
          map: { selected: false, target: null, max: null },
          split: { value: 20 }
        }
      },
      presentPaths: {},
      presentDias: {},
      presentTrain: {},
      presentTest: {},
      displayImages: [],
      displayImagesCache: [],
      selectedImages: {},
      totalP: 0,
      totalD: 0,
      render: 0,
      displayGallery: false,
      pathTable: [],
      diaTable: [],
      testTable: [],
      trainTable: [],
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'count',
          sortable: true
        }],
      filterChoice: '',
      selectedPaths: [],
      search: '',
      pageNumber: 0,
      itemsPerPage: 16,
      results: [],
      series: [{
        name: 'MAP',
        data: []
      }],
      seriesPR: [{
        name: 'Precision',
        data: []
      },
      {
        name: 'Recall',
        data: []
      }],
      seriesCon: [{
        name: 'TP',
        data: []
      },
      {
        name: 'FP',
        data: []
      },
      {
        name: 'FN',
        data: []
      }],
      seriesF1: [{
        name: 'F1',
        data: []
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Mean Average Precision by Iteration',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '12px'
          }
        },
        fill: {
          type: 'soild',
          gradient: {
            // shadeIntensity: 1,
            // inverseColors: false,
            // opacityFrom: 0.4,
            // opacityTo: 0,
            // stops: [0, 60, 100]
          }
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          labels: {
            show: true
          },
          categories: []
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5,
          decimalsInFloat: 2,
          labels: {
            style: {
              colors: ['#ffffff'],
              fontFamily: 'Lato',
              fontSize: '10px'
            },
            formatter: function (val) { return val.toFixed(2) }
          }
        }
      },
      chartOptionsCon: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Confusion Matrix by Iteration',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '12px'
          }
        },
        fill: {
          type: 'soild',
          gradient: {
            // shadeIntensity: 1,
            // inverseColors: false,
            // opacityFrom: 0.4,
            // opacityTo: 0,
            // stops: [0, 60, 100]
          }
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          labels: {
            show: true
          },
          categories: []
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5,
          decimalsInFloat: 2,
          labels: {
            style: {
              colors: ['#ffffff'],
              fontFamily: 'Lato',
              fontSize: '10px'
            },
            formatter: function (val) { return val.toFixed(2) }
          }
        }
      },
      chartOptionsPr: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Precision and Recall by Iteration',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '12px'
          }
        },
        fill: {
          type: 'soild',
          gradient: {
            // shadeIntensity: 1,
            // inverseColors: false,
            // opacityFrom: 0.4,
            // opacityTo: 0,
            // stops: [0, 60, 100]
          }
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          labels: {
            show: true
          },
          categories: []
        },
        yaxis: {
          min: 0,
          max: 1,
          tickAmount: 5,
          decimalsInFloat: 2,
          labels: {
            style: {
              colors: ['#ffffff'],
              fontFamily: 'Lato',
              fontSize: '10px'
            },
            formatter: function (val) { return val.toFixed(2) }
          }
        }
      },
      options: {
        chart: {
          height: 300,
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '24px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '12px'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.4,
            opacityTo: 0,
            stops: [0, 60, 100]
          }
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          labels: {
            show: false
          }
        },
        yaxis: {
          min: 0,
          max: 1,
          tickAmount: 5,
          decimalsInFloat: 2,
          labels: {
            style: {
              colors: ['#ffffff'],
              fontFamily: 'Lato',
              fontSize: '10px'
            },
            formatter: function (val) { return val.toFixed(2) }
          }
        }
      },
      chartOptionsF1: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'F1-Score by Iteration',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '12px'
          }
        },
        fill: {
          type: 'soild',
          gradient: {
            // shadeIntensity: 1,
            // inverseColors: false,
            // opacityFrom: 0.4,
            // opacityTo: 0,
            // stops: [0, 60, 100]
          }
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          labels: {
            show: true
          },
          categories: []
        },
        yaxis: {
          min: 0,
          max: 1,
          tickAmount: 5,
          decimalsInFloat: 2,
          labels: {
            style: {
              colors: ['#ffffff'],
              fontFamily: 'Lato',
              fontSize: '10px'
            },
            formatter: function (val) { return val.toFixed(2) }
          }
        }
      },
      pieOptions: {
        chart: {
          type: 'donut'
        },
        title: {
          text: 'Product Trends by Month',
          floating: true,
          align: 'left',
          offsetY: 0,
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '20px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          floating: true,
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '10px'
          }
        },
        legend: {
          position: 'left',
          offsetY: 50,
          fontFamily: 'Lato',
          fontSize: '12px',
          labels: {
            colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
          }
        },
        dataLabels: {
          style: {
            fontFamily: 'Lato, sans-serif',
            fontSize: '8px'
          }
        },
        colors: ['#28a745', '#ffc107', '#dc3545', '#17a2b8', '#2673bc'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 100
            },
            legend: {
              position: 'top'
            }
          }
        }]
      }
    }
  },
  computed: {
    ...mapState('labelledImagesSubmission', {
      images: (state) => state
    }),
    ...mapState('usersSubmission', { members: (state) => state }),
    imagesLoaded () {
      return this.images.loaded
    },
    ...mapState(
      { projects: (state) => state.projectSubmission }),
    selectedImageCount () {
      return Object.values(this.selectedImages).filter(Boolean).length
    },
    confusionMatrix () {
      const tmp = this.project.training_config[0]
      if (tmp && tmp.results && tmp.results.c) {
        const highestTPObject = tmp.results.c.reduce((max, current) => {
          return (current.TP > max.TP) ? current : max
        })
        // const TN = tmp.ttsplit.details.classses.test
        // const _TN = Object.values(TN).reduce((sum, current) => {
        //   return sum + current
        // }, 0)
        // console.log('TN', TN)
        return {
          truePositive: highestTPObject.TP,
          falsePositive: highestTPObject.FN,
          falseNegative: highestTPObject.FP
          // trueNegative: _TN - highestTPObject.TP - highestTPObject.FN - highestTPObject.FP
        }
      } else {
        return {
          truePositive: 0,
          falsePositive: 0,
          falseNegative: 0,
          trueNegative: 0
        }
      }
    },
    cmOptions () {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['True Positive', 'False Positive', 'False Negative']
      _options.title.text = (this.confusionMatrix.truePositive + this.confusionMatrix.falsePositive +
      this.confusionMatrix.falseNegative === 0 ? 'Not Enough Data' : (Math.round(
          (this.confusionMatrix.truePositive) /
        (this.confusionMatrix.truePositive + this.confusionMatrix.falsePositive +
        this.confusionMatrix.falseNegative) * 10000) / 100).toFixed(2) + '%')
      _options.subtitle.text = 'Accuracy of AI Solution'

      return _options
    },
    cmSeries () {
      return [this.confusionMatrix.truePositive, this.confusionMatrix.falsePositive, this.confusionMatrix.falseNegative]
    },
    aucOptions () {
      const _options = JSON.parse(JSON.stringify(this.options))
      _options.xaxis.type = 'numeric'
      _options.xaxis.min = 0
      _options.xaxis.max = 1
      _options.xaxis.categories = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95]
      _options.title.text = 'place holder'// this.auc.toFixed(4)
      _options.subtitle.text = 'Area Under Curve'

      return _options
    },
    aucSeries () {
      // const _sorted = this.metrics.slice()
      // _sorted.sort((a, b) => { return a.FRP - b.FRP })

      // return [{
      //   data: _sorted.map(__metrics => { return { x: __metrics.FPR, y: __metrics.TPR } })
      // }]
      return [{ data: [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95] }]
    },
    prOptions () {
      const _options = JSON.parse(JSON.stringify(this.options))
      _options.xaxis.type = 'numeric'
      _options.xaxis.min = 0
      _options.xaxis.max = 1
      _options.xaxis.categories = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95]
      _options.title.text = 'place holder'// this.metrics.find(__metrics => __metrics.threshold === 0.5) ? this.metrics.find(__metrics => __metrics.threshold === 0.5).accuracy.toFixed(4) : 0
      _options.subtitle.text = 'Precision-Recall Curve'

      return _options
    },
    prSeries () {
      // return [{
      //   data: this.metrics.map(__metrics => { return { x: __metrics.precision, y: __metrics.recall } })
      // }]

      return [{ data: [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95] }]
    },
    reducedImages () {
      var _images = this.displayImages
      if (this.filterChoice !== 'All' && this.filterChoice !== '') {
        _images = _images.filter(img => img.details.pathologies.has(this.filterChoice))
      }
      if (this.search !== '') {
        _images = _images.filter(img => this.inSearch(img, this.search))
      }
      return _images
    },
    paginatedData () {
      const start = this.pageNumber * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.reducedImages.slice(start, end)
    },
    maxPages () {
      return (this.reducedImages.length / this.itemsPerPage >> 0) + 1
    },
    readers () {
      // // // // // console.log('read', this.members.members)
      if (this.members && this.members.members && this.members.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Reader') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    moderators () {
      if (this.members && this.members.members && this.members.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Moderator') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    control_selected () {
      var keys = Object.keys(this.config.controls)
      var op = false
      keys.forEach(k => {
        if (this.config.controls[k].selected && (this.config.controls[k].target || this.config.controls[k].max)) {
          op = true
        }
      })
      return op
    },
    projectWatch () {
      return this.projects.loading
    }
  },
  watch: {
    imagesLoaded (_new) {
      if (_new) {
        this.displayImages = this.transformImageData()
        if (this.project.training_config && this.project.training_config.length > 0) {
          this.restore_config()
          this.paths_selected()
        }
        this.render++
      }
    },
    projectWatch (_new) {
      // console.log('projectWatch')
      // console.log('projectWatch')
      if (!_new) {
        if (this.project.training_config && this.project.training_config.length > 0) {
          this.restore_config()
          this.paths_selected()
        }
        if (this.project.training_config && this.project.training_config.length > 0 && this.project.training_config[0] && this.project.training_config[0].results) {
          // console.log('reloading mapping results')
          var _s = this.project.training_config[0].results.map.map(_r => _r['mAP@0.50'])
          var _c = this.project.training_config[0].results.map.map(_r => _r.Iteration)
          var F1Score = this.project.training_config[0].results.c.map(_r => _r['F1-score'])
          var TP = this.project.training_config[0].results.c.map(_r => _r.TP)
          var FN = this.project.training_config[0].results.c.map(_r => _r.FN)
          var FP = this.project.training_config[0].results.c.map(_r => _r.FP)
          var precision = this.project.training_config[0].results.c.map(_r => _r.precision)
          var recall = this.project.training_config[0].results.c.map(_r => _r.recall)
          var range = []
          for (let i = 0; i <= this.project.training_config[0].results.c.length; i++) {
            range.push(i)
          }
          // console.log(_s)
          // console.log(_c)
          var _series = [{ name: 'MAP', data: _s }]

          var seriesPR = [{
            name: 'Precision',
            data: precision
          },
          {
            name: 'Recall',
            data: recall
          }]
          var seriesCon = [{
            name: 'TP',
            data: TP
          },
          {
            name: 'FN',
            data: FN
          },
          {
            name: 'FP',
            data: FP
          }]
          var seriesF1 = [{
            name: 'F1',
            data: F1Score
          }]
          var _chartOptions = {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: 'Mean Average Precision by Iteration',
              align: 'left',
              style: {
                color: '#ffffff',
                fontFamily: 'Lato',
                fontSize: '12px'
              }
            },
            fill: {
              type: 'soild',
              gradient: {
                // shadeIntensity: 1,
                // inverseColors: false,
                // opacityFrom: 0.4,
                // opacityTo: 0,
                // stops: [0, 60, 100]
              }
            },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: false
                }
              }
            },
            xaxis: {
              labels: {
                show: true
              },
              categories: _c
            },
            yaxis: {
              min: 0,
              max: 100,
              tickAmount: 5,
              decimalsInFloat: 2,
              labels: {
                style: {
                  colors: ['#ffffff'],
                  fontFamily: 'Lato',
                  fontSize: '10px'
                },
                formatter: function (val) { return val.toFixed(2) }
              }
            }
          }

          var chartOptionsCon = {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: 'Confusion Matrix by Iteration',
              align: 'left',
              style: {
                color: '#ffffff',
                fontFamily: 'Lato',
                fontSize: '12px'
              }
            },
            fill: {
              type: 'soild',
              gradient: {
                // shadeIntensity: 1,
                // inverseColors: false,
                // opacityFrom: 0.4,
                // opacityTo: 0,
                // stops: [0, 60, 100]
              }
            },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: false
                }
              }
            },
            xaxis: {
              labels: {
                show: true
              },
              categories: range
            },
            yaxis: {
              min: 0,
              max: 100,
              tickAmount: 5,
              decimalsInFloat: 2,
              labels: {
                style: {
                  colors: ['#ffffff'],
                  fontFamily: 'Lato',
                  fontSize: '10px'
                },
                formatter: function (val) { return val.toFixed(2) }
              }
            }
          }
          var chartOptionsPr = {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: 'Precision and Recall by Iteration',
              align: 'left',
              style: {
                color: '#ffffff',
                fontFamily: 'Lato',
                fontSize: '12px'
              }
            },
            fill: {
              type: 'soild',
              gradient: {
                // shadeIntensity: 1,
                // inverseColors: false,
                // opacityFrom: 0.4,
                // opacityTo: 0,
                // stops: [0, 60, 100]
              }
            },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: false
                }
              }
            },
            xaxis: {
              labels: {
                show: true
              },
              categories: range
            },
            yaxis: {
              min: 0,
              max: 1,
              tickAmount: 5,
              decimalsInFloat: 2,
              labels: {
                style: {
                  colors: ['#ffffff'],
                  fontFamily: 'Lato',
                  fontSize: '10px'
                },
                formatter: function (val) { return val.toFixed(2) }
              }
            }
          }
          var chartOptionsF1 = {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: 'F1-Score by Iteration',
              align: 'left',
              style: {
                color: '#ffffff',
                fontFamily: 'Lato',
                fontSize: '12px'
              }
            },
            fill: {
              type: 'soild',
              gradient: {
                // shadeIntensity: 1,
                // inverseColors: false,
                // opacityFrom: 0.4,
                // opacityTo: 0,
                // stops: [0, 60, 100]
              }
            },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: false
                }
              }
            },
            xaxis: {
              labels: {
                show: true
              },
              categories: range
            },
            yaxis: {
              min: 0,
              max: 1,
              tickAmount: 5,
              decimalsInFloat: 2,
              labels: {
                style: {
                  colors: ['#ffffff'],
                  fontFamily: 'Lato',
                  fontSize: '10px'
                },
                formatter: function (val) { return val.toFixed(2) }
              }
            }
          }

          this.seriesPR = seriesPR
          this.seriesCon = seriesCon
          this.seriesF1 = seriesF1
          this.series = _series
          this.chartOptionsPr = chartOptionsPr
          this.chartOptionsCon = chartOptionsCon
          this.chartOptionsF1 = chartOptionsF1
          this.chartOptions = _chartOptions
        }
        this.render++
      }
    }
  },
  methods: {
    ...mapActions('projectSubmission', {
      updateUserProject: 'updateProject'
    }),
    ...mapActions('projectSubmission', ['trainingBegin', 'stopTraining', 'trainingRestart', 'getProjectImages2', 'saveMetaData', 'getUserProjects', 'startTraining', 'pollTraining', 'trainingStatus']),
    trainingStart () {
      this.startTraining(this.project._id)
    },
    async getStatus () {
      const _status = await this.trainingStatus()
      const status = JSON.parse(_status.replace(/Infinity/g, '"Infinity"'))
      if (status && status.training_thread_running) {
        this.status = status.training_thread_running
      } else {
        this.status = 'Trainer Error'
      }
      console.log('t status', status.training_thread_running)
    },
    beginTraining () {
      console.log('init training')
      this.trainingBegin(this.project._id)
    },
    restartTraining () {
      console.log('restart training')
      this.trainingRestart(this.project._id)
    },
    _stopTraining () {
      console.log('stop training')
      this.stopTraining(this.project._id)
    },
    trainingPoll () {
      this.pollTraining(this.project._id)
    },
    inSearch (img, val) {
      if (img.details.image_set && img.details.image_set.name) {
        return img.details.image_set.name.includes(val) || img.details.image_set.sequence.includes(val)
      } else {
        return img.details.imgName.includes(val)
      }
    },
    setItemsPerPage (x) {
      this.itemsPerPage = x
    },
    setPage (x) {
      this.pageNumber = x
    },
    nextPage () {
      this.pageNumber++
    },
    prevPage () {
      this.pageNumber--
    },
    save_config () {
      var op = this.config
      var selImg = []
      Object.keys(this.selectedImages).forEach(k => {
        if (this.selectedImages[k]) {
          selImg.push(k)
        }
      })
      op.selected_images = selImg
      var paths = []
      Object.keys(this.presentPaths).forEach(k => {
        if (this.presentPaths[k].selected) {
          paths.push(k)
        }
      })
      op.pathologies = paths
      // // console.log(op)
      var _project = this.project
      if (_project.training_config && _project.training_config.length > 0) {
        _project.training_config[0] = op
      } else {
        _project.training_config = []
        _project.training_config.push(op)
      }
      this.updateUserProject(_project)
    },
    restore_config () {
      // console.log('restore')
      this.config = this.projects.projects.find(p => p._id === this.projects.selectedProjectId).training_config[0]
      // this.config = this.projects.projects.find(p => p._id === this.projects.selectedProjectId).training_config[0]
      // this.presentPaths = {}
      this.project.training_config[0].pathologies.forEach(p => {
        this.presentPaths[p].selected = true
      })
      this.project.training_config[0].selected_images.forEach(img => {
        this.selectedImages[img] = true
      })
      if (this.config.ttsplit && this.config.ttsplit.details) {
        // // console.log('ttspklit: ', this.config.ttsplit.details)
        // // console.log(this.presentPaths)
        // // console.log('ttspklit: ', this.config.ttsplit.details.classses.test)
        this.presentTest = {}
        Object.keys(this.config.ttsplit.details.classses.test).forEach(t => {
          this.presentTest[t] = { selected: true, count: this.config.ttsplit.details.classses.test[t] }
        })
        this.presentTrain = {}
        Object.keys(this.config.ttsplit.details.classses.train).forEach(t => {
          this.presentTrain[t] = { selected: true, count: this.config.ttsplit.details.classses.train[t] }
        })
        this.testTable = []
        Object.keys(this.presentTest).forEach(p => {
          this.testTable.push({ name: p, count: this.presentTest[p].count })
        })
        this.testTable.sort(function (a, b) { return b.count - a.count })

        this.trainTable = []
        Object.keys(this.presentTrain).forEach(p => {
          this.trainTable.push({ name: p, count: this.presentTrain[p].count })
        })
        this.trainTable.sort(function (a, b) { return b.count - a.count })
        // console.log('tt split')
        // console.log(this.presentTest)
        // console.log(this.presentTrain)
        // // console.log('ttspklit: ', this.config.ttsplit.details.classses.train)
      }
    },
    paths_selected (e) {
      // // console.log('checking paths', e)
      var keys = Object.keys(this.presentPaths)
      var op = false

      var paths = []
      keys.forEach(k => {
        if (this.presentPaths[k].selected) {
          paths.push(k)
          op = true
        }
      })
      paths = [...new Set(paths)]
      this.selectedPaths = paths
      // // console.log('checking paths')
      this.render++
      this.displayGallery = op
    },
    selectAll () {
      this.displayImages.forEach(_image => {
        this.$set(this.selectedImages, _image.id, true)
      })
      // this.displayImages.filter(_image => !(_image.details.aiIov && _image.details.aiIov >= 0)).forEach(_image => {
      //   this.$set(this.selectedImages, _image.id, true)
      // })
    },
    transformImageData () {
      // this.restore_config()
      const data = this.images.labelledImages
      if (!Array.isArray(data)) {
        return []
      }

      this.presentPaths = {}
      this.presentDias = {}
      this.totalD = 0
      this.totalP = 0
      var transformedData = []
      var acc
      // // // // // console.log(!filterFlag)
      transformedData = []
      for (const item of data) {
        console.log(item)
        var _pathologies = []
        item.pathologies.forEach(sub => {
          _pathologies.push(sub)
        })

        // // console.log(_pathologies)
        _pathologies.forEach(p => {
          // // console.log(this.presentPaths[p])
          this.totalP++
          if (this.presentPaths[p]) {
            this.presentPaths[p].count++
          } else {
            this.presentPaths[p] = { count: 1, selected: false }
          }
        })
        console.log(item)
        var _dias = []
        item.diagnoses.forEach(sub => {
          _dias.push(sub)
        })

        // // console.log(_pathologies)
        _dias.forEach(p => {
          // //_diasconsole.log(this.presentPaths[p])
          this.totalP++
          if (this.presentDias[p]) {
            this.presentDias[p].count++
          } else {
            this.presentDias[p] = { count: 1, selected: false }
          }
        })
        transformedData.push({
          id: item.imageId,
          url: apiRoute() + '/image/' + item.imageId,
          styleFlag: 'white',
          details: {
            created: item.image.created_at,
            format: item.image.contentType,
            imgName: item.image.name,
            meta: item.image.metadata,
            iou: acc,
            pathologies: new Set(item.pathologies),
            tags: new Set(item.tags),
            diagnoses: new Set(item.diagnoses),
            image_set: item.image_set && item.image_set.name ? item.image_set : null,
            otherImages: item.otherImages
          }
        })
      }
      // // // // // console.log(transformedData)
      this.pathTable = []
      Object.keys(this.presentPaths).forEach(p => {
        this.pathTable.push({ name: p, count: this.presentPaths[p].count })
      })
      this.pathTable.sort(function (a, b) { return b.count - a.count })
      this.diaTable = []
      Object.keys(this.presentDias).forEach(p => {
        this.diaTable.push({ name: p, count: this.presentDias[p].count })
      })
      this.diaTable.sort(function (a, b) { return b.count - a.count })
      this.displayImagesCache = transformedData
      return transformedData
    }
  },
  mounted () {
    this.displayImages = this.transformImageData()
    if (this.project.training_config && this.project.training_config.length > 0) {
      this.restore_config()
      this.paths_selected()
    }
    if (this.project.training_config && this.project.training_config.length > 0 && this.project.training_config[0] && this.project.training_config[0].results) {
      // console.log('mapping results')
      var _s = this.project.training_config[0].results.map.map(_r => _r['mAP@0.50'])
      var _c = this.project.training_config[0].results.map.map(_r => _r.Iteration)
      var TP = this.project.training_config[0].results.c.map(_r => _r.TP)
      var F1Score = this.project.training_config[0].results.c.map(_r => _r['F1-score'])
      var FN = this.project.training_config[0].results.c.map(_r => _r.FN)
      var FP = this.project.training_config[0].results.c.map(_r => _r.FP)
      var precision = this.project.training_config[0].results.c.map(_r => _r.precision)
      var recall = this.project.training_config[0].results.c.map(_r => _r.recall)
      this.series[0].data = _s
      this.seriesCon[0].data = TP
      this.seriesCon[1].data = FP
      this.seriesCon[2].data = FN
      this.seriesPR[0].data = precision
      this.seriesPR[1].data = recall
      this.seriesF1[0].data = F1Score
      var range = []
      for (let i = 0; i <= this.project.training_config[0].results.c.length; i++) {
        range.push(i)
      }
      this.chartOptions.xaxis.categories = _c
      this.chartOptionsCon.xaxis.categories = range
      this.chartOptionsPr.xaxis.categories = range
      this.chartOptionsF1.xaxis.categories = range
    }
    this.getStatus()
    this.intervalId = setInterval(() => {
      this.getStatus()
    }, 60000)
    this.render++
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
  }
}
</script>

<style scoped>
h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.row {
  margin-bottom: 1rem;
}

p {
  font-size: 14px;
  margin-bottom: 10px;
}

.color-white {
  color: #fff;
}

.currentSubgroups {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(70vh);
}

.selectrow {
  border-top: 1px solid #1d4b70;
  padding-top: 3px;
}

.targetBox {
  max-height: 15vh;
  overflow-y: scroll;
  /* border: 1px solid #cccccc72;
  border-radius: 5px; */
}

.check_inputs {
  margin-bottom: 3px;
  border-bottom: 1px solid #1d4b70;
}

.image-checkbox {
  position: absolute;
  opacity: 1;
  width: 20px;
  height: 20px;
  top: -12px;
  left: -12px;
  cursor: pointer;
}

.subgroup-selection {
  border: 1px solid #cccccc81;
}

.subgroupFlag:not(:first-of-type) {
  border-top: 1px solid #1d4b70;
}

.groupflag {
  border-top: 1px solid #cccccc2d;
}

.valueflag {
  border-top: 1px solid #cccccc2d;
  /* border-left: 1px solid #cccccc81; */
}

.sel {
  border-top: 1px solid #cccccc2d;
  /* border-left: 1px solid #cccccc81; */
}

.tag-inbox input,
.pathology-inbox input,
.diagnosis-inbox input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  font-size: 14px;
}

.tag-pills,
.pathology-pills,
.diagnosis-pills {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.tag-pill,
.pathology-pill,
.diagnosis-pill {
  display: flex;
  align-items: center;
  border-color: #ccc;
  color: #fff;
  border: 1px solid #ccc;
  padding: 4px 10px;
  border-radius: 20px;
  font-weight: 500;
}

.tag-remove,
.pathology-remove,
.diagnosis-remove {
  cursor: pointer;
  margin-left: 5px;
  font-weight: bold;
}

.pathology-color {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
}

.form-control-pathology {
  width: 40px;
  height: 40px;
  padding: 0;
}

.submit-button {
  margin-top: 20px;
}

.current-data-container {
  display: flex;
  overflow-x: y;
  gap: 20px;
  padding-top: 10px;
  margin-top: 10px;
}

.current-data-column {
  flex: 1;
  max-width: 100%;
  padding: 10px;
  border-radius: 8px;
  overflow-y: auto;
  position: relative;
}

.form-control-pathology-color {
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  appearance: none;
  cursor: pointer;
}

.img-fluid {
  border-radius: 10px;
  border: 2px solid white;
}

.image-container {
  position: relative;
  margin: 10px;
  cursor: pointer;
  /* Add cursor pointer for clickable effect */
}

.indicator-set {
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 35px;
  left: 5px;
}

.title_staging {
  color: azure
}

div>strong {
  color: aliceblue;
}

.title_staging>strong {
  color: aliceblue;
}
</style>
